<template>
    <div>
        <div class="recruitment">
            <div>
                <div class="title">
                    <h2>人才招聘</h2>
                    <p>Recruitment</p>
                </div>
                <div class="main" style="margin:0 auto">
                  <el-table
                    class="table"
                    :data="tableData"
                    border
                    :span-method="spanMethod"
                    >
                    <el-table-column
                      prop="id"
                      label="序号"
                      width="50">
                    </el-table-column>
                    <el-table-column
                      prop="work"
                      label="招聘岗位"
                      width="180">
                    </el-table-column>
                    <el-table-column
                      prop="type"
                      label="专业要求">
                    </el-table-column>
                    <el-table-column
                      prop="edu"
                      label="学历">
                    </el-table-column>
                    <el-table-column
                      prop="count"
                      label="拟招聘人数">
                    </el-table-column>
                  </el-table>
                </div>
                <div class="main1 col8">
                  一、公司福利：<br/>1、公司提供五险（养老保险、医疗保险、失业保险、工伤保险、生育保险）；<br/>2、实行标准工时制度，5天7.5小时（上班时间：8:30—12：00， 14:00—18:00），双休，享有国家法定节假日；<br/>3、免费年度体检、工作餐补贴、员工活动、 公费培训等；<br/>4、公司不定期组织各类员工活动，丰富员工业余生活，如聚餐、团建、中秋博饼、年终尾牙等；<br/>5、给以新人入职培训、专业知识培训、日常学习等，让你在工作中不断成长，全方面提升工作素质及能力；<br/>6、为符合条件的应届生办理档案接收及落户；给予新引进人才生活补贴（本科1万，硕士5万，博士8万）<br/>二、地址：厦门市湖里区湖里大道33号西侧七楼（福建历思司法鉴定所）<br/>三、联系人：陈小姐 15980814232<br/>四、简历投递邮箱：1240018668@qq.com<br/>五、乘车路线：26路/302路/323路/109路/848路/655路华昌站下车 （联发集团保安亭边）

                </div>
                
                <!-- <ul class="list clearfix">
                
                    <li class="fl p-r" @click="goDetails(item)" v-for="item in data" :key="item.id">
                        <h3 class="t-oe">{{item.label}}</h3>
                        <div class="clearfix icon-box">
                            <div class="fl">
                                <i class="d-ib"></i>
                                <span>{{item.year}}</span>
                            </div>
                            <div class="fl">
                                <i class="d-ib"></i>
                                <span>{{item.education}}</span>
                            </div>
                            <div class="fl">
                                <i class="d-ib"></i>
                                <span>{{item.count}}</span>
                    
                            </div>
                        </div>
                        <div class="p">
                            <p>{{item.duty}}</p>
                            
                            
                        </div>
                        <i class="d-b"></i>
                        <em class="p-a"></em>
                        <strong class="p-a"></strong>
                        <strong class="p-a"></strong>
                        <strong class="p-a"></strong>
                    </li>
                    
                </ul>
                 -->
                
            </div>
        </div>
            <!-- <el-dialog
                    title=""
                    :visible.sync="dialogVisible"
                    custom-class="dialogStyle"
                    :before-close="handleClose">
                    <div class="content ">
                        <h3 class="label">{{details.label}}</h3>
                        <div class=" icon-box clearfix">
                            <div class="fl">
                                <i class="icon"></i>
                                <span>{{details.year}}</span>
                            </div>
                            <div class="fl">
                                <i class="icon"></i>
                                <span>{{details.education}}</span>
                            </div>
                            <div class="fl">
                                <i class="icon"></i>
                                <span>{{details.count}}</span>
                    
                            </div>
                        </div>
                        <div class="p">
                            <p>{{details.duty}}</p>
                            <p>{{details.content}}</p>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <div class="href">
                            <a class="click-form" style="cursor: pointer"  title="" @click="goForm()">立即申请</a>
                        </div>
                    </span>
                 </el-dialog>
                 <el-dialog
                    title=""
                    :visible.sync="dialogFormVisible"
                    custom-class="formStyle"
                    :before-close="handleFormClose">
                    <div class="content">
                        <h3 class="label">在线申请</h3>
                        <el-form :model="form" ref="form" :rules="rules">
                            <el-form-item label="姓名" prop="name" :label-width="formLabelWidth">
                                <el-input v-model="form.name" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="电话" prop="phone" :label-width="formLabelWidth">
                                <el-input v-model="form.phone" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="E-mail" prop="email" :label-width="formLabelWidth">
                                <el-input v-model="form.email" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="个人简介" prop="intro" :label-width="formLabelWidth">
                                <el-input type="textarea" :rows="2" v-model="form.intro" autocomplete="off"></el-input>
                            </el-form-item>
                            <div class="upload">
                                <el-upload
                                    class="upload-demo"
                                    ref="upload"
                                    action=""
                                    :multiple="false"
                                    :on-preview="handlePreview"
                                    :on-remove="handleRemove"
                                    :file-list="fileList"
                                    :on-change="onChange"
                                    :auto-upload="false">
                                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                    </el-upload>
                            </div>
                            
                        </el-form>
                        
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <el-button class="uploadBtn" @click="validForm">提交</el-button>
                    </div>
                 </el-dialog> -->
        
    </div>
</template>

<script>
export default {
    data(){
        return{
          tableData:[
            {id:'1',work:'实验员/有机工程师',type:'化学、药学、法医、检验',edu:'本科及以上',count:'5人',},
            {id:'2',work:'鉴定助理',type:'环境工程、环境科学、生态学、生物化学、生物技术',edu:'本科及以上',count:'5人',},
            {row:2,col:1,id:'3',work:'销售助理',type:'不限',edu:'大专及以上',count:'3-5人',},
            {row:0,col:0,work:'销售工程师',type:'不限',edu:'大专及以上',count:'3-5人',},
            {row:3,col:1,id:'4',work:'硬件工程师',type:'电子、测控仪器、光学',edu:'本科及以上',count:'3-5人',},
            {row:0,col:0,work:'测试工程师',type:'计算机相关专业',edu:'大专及以上',count:'3-5人',},
            {row:0,col:0,work:'嵌入式工程师',type:'计算机相关专业',edu:'本科及以上',count:'3-5人',},
            {row:2,col:1,id:'5',work:'研发助理',type:'生物化学相关专业',edu:'硕士及以上',count:'10人',},
            {row:0,col:0,work:'研发工程师',type:'生物化学相关专业',edu:'博士及以上',count:'5人',},
          ],
            fileList:[],
            rules:{
                name:{required:true,message:'必填',trigger: 'blur'},
                phone:{required:true,message:'必填',trigger: 'blur'},
                email:{required:true,message:'必填',trigger: 'blur'},
            },
            dialogVisible:false,
            dialogFormVisible:false,
            form:{name:'',phone:'',email:'',intro:''},
            formLabelWidth: '120px',
            data:[
                {
                    label:'测试工程师',
                    year:'1-3年',
                    education:'本科',
                    count:'若干',
                    duty:'岗位职责： 1. 编写测试计划、测试用例；2. 搭建测试环境，进行功能测试、性能测试，反馈Bug List，并跟踪推动软件缺陷的修复；3. 必要时能够使用自动化工具完成一些重复性的测试，提高效率。4. 编写测试报告、用户手册等文档；',
                    content:'任职要求1. 大专及以上的学历，热爱测试工作；2. 一年以上软件测试经验、仪器测试经验；3. 具备优秀的学习和探索能力，具备良好的问题分析能力，有责任心、有耐心；4. 能够适应中长期出差；5. 有使用C#独立编写上位机软件经验者优先；'

                },
            ],
            details:{},
        }
    },
    
    methods:{
      handleData(){

      },
      spanMethod({ row,columnIndex  }){
        if(columnIndex ==0){
          console.log(row)
          if('row' in row){
            console.log(row)
            return {
              rowspan: row.row,
              colspan: row.col
            }
          }
        }
      },
        goDetails(item){
            this.details = item;
            this.dialogVisible = true;
        },
        handleClose(){
            this.dialogVisible = false;
        },
        handleFormClose(){
            this.dialogFormVisible = false;
            this.$refs.form.resetFields();
            this.$refs.form.clearValidate();
            this.fileList = [];
        },
        goForm(){
            this.dialogFormVisible = true;
        },
        validForm(){
            this.$refs.form.validate(valid=>{
                if(valid){//表单验证
                    if(!this.fileList.length){
                        this.$message({message:'请选择文件',type:'warning'});
                    }else{
                        // 上传
                        this.form.file = this.fileList[0].rows
                    }
                    // if()
                }else{
                    return false;
                }
            })
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        onChange(file){
            
            this.fileList= [file]
        },
    }

}
</script>
<style src="../../layout.css" scoped></style>
<style scoped>
.main1{
  line-height:2rem;
  text-align:left;
  margin:2rem auto;
}
>>>.table .el-table__cell{
  border-right: 1px solid #8e8e8e;
  border-bottom: 1px solid #828282!important;
}
.main{
  border:1px solid #8e8e8e;
}
</style>
<style scoped >

body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td,hr,button,article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{margin:0;padding:0}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section { display:block; }
table{border-collapse:collapse;border-spacing:0}
audio,canvas,video { display: inline-block;*display: inline;*zoom: 1;}
fieldset,img{border:0}
address,caption,cite,code,dfn,th,var{font-style:normal;font-weight:normal}
ol,ul{list-style:none}
ol{list-style-type:none;counter-reset:sectioncounter;}
ol li:before {
    content:counter(sectioncounter) ".";
    counter-increment:sectioncounter;
}
caption,th{text-align:left}
h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}
q:before,q:after{content:''}
abbr,acronym{border:0;font-variant:normal}
sup{vertical-align:text-top}
sub{vertical-align:text-bottom}
input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit}
input,textarea,select{*font-size:100%}
a{text-decoration: none;color: #000;}
input{outline:none;}
body{
    font-family: Regular , "微软雅黑";
}
img{display: block;width: 100%;height: auto;}

/*宽*/
.w100{
    width: 100%;
}



/*高*/
.h100{
    height: 100%;
}

/*浮动*/
.fl{
    float: left;
}
.fr{
    float: right;
}

/*清除浮动*/
.recruitment .clearfix:after{
    content: '';
    clear: both;
    display: block;
}


/*display*/
.d-b{
    display: block;
}
.d-ib{
    display: inline-block;
}
.d-n{
    display: none;
}
.d-f{
    display: flex;
}

/*定位*/
.p-r{
    position: relative;
}
.p-a{
    position: absolute;
}
.p-f{
    position: fixed;
}
.p p{
    padding-bottom:20px;
    text-indent: 2em;
}
/*margin*/
.m-a{
    margin: auto;
}

/*cursor*/
.c-p{
    cursor: pointer;
}

/*超出隐藏*/
.ov-h{
    overflow: hidden;
}
.t-oe{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.recruitment{
    padding: 60px 0;
}
.recruitment > div{
    max-width: 100%;
    margin: auto;
}
.recruitment .title{
    margin-bottom: 55px;
    text-align: center;
}
.recruitment .title h2{
    margin-bottom: 10px;
    color: #333;
    font-size: 24px;
}
.recruitment .title p{
    color: #808080;
    font-family: hyqh25jf;
    font-size: 22px;
}
.recruitment .list li{
    overflow: hidden;
    width:30%;
    margin-left: 2.15%;
    margin-bottom: 50px;
    padding: 60px 65px 35px;
    background-color: #fafafa;
    box-sizing: border-box;
    transition: 1.5s;
    cursor: pointer;
}
/* .recruitment .list li:nth-child(3n+1){
    margin-left:0;
} */
.recruitment .list li h3{
    margin-bottom: 25px;
    color: #1e2120;
    font-size: 18px;
}
.recruitment .list li > div{
    margin-bottom: 35px;
}
.recruitment .list li div div{
    color: #939393;
    font-size: 16px;
}
/* .recruitment .list li div div + div{
    margin-left: 50px;
} */
.recruitment .list li div div i{
    width: 14px;
    height: 14px;
    margin-right: 5px;
    background: url("../../assets/Talents/develop/biaoqian.png") no-repeat center;
}
.recruitment .list li div div i,
.recruitment .list li div div span{
    vertical-align: middle;
}
.recruitment .list li .p{
    margin-bottom: 30px;
    color: #686868;
    font-size: 16px;
    line-height: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.recruitment .list li .p p{
    display: inline !important;
}
.recruitment .list li > i{
    width:31px ;
    height: 31px;
    background: url("../../assets/Talents/develop/i.png") no-repeat center;
}
.recruitment .list li em{
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #367fb8;
}
.recruitment .list li > strong{
    width: 222px;
    height: 222px;
    background-color: rgba(255,255,255,.14);
    border-radius: 50%;
    transition: 1s;
}
.recruitment .list li > strong:nth-of-type(1){
    top: -100px;
    right: -20px;
    transform: rotate(-100deg);
    transform-origin: top;
}
.recruitment .list li > strong:nth-of-type(2){
    top: -50px;
    right: -140px;
    transform: rotate(-70deg);
    transform-origin: right;
}
.recruitment .list li > strong:nth-of-type(3){
    bottom: -160px;
    right: -80px;
    transform-origin: bottom;
    transform: rotate(60deg);
}
.recruitment .list li:hover{
    background-color: #367fb8;
}
.recruitment .list li:hover h3,
.recruitment .list li:hover div div,
.recruitment .list li:hover .p{
    color: white;
}
.recruitment .list li:hover div div i{
    background-image: url("../../assets/Talents/develop/biaoqian1.png");
}
.recruitment .list li:hover > i{
    background-image: url("../../assets/Talents/develop/i1.png");
}
.recruitment .list li:hover em{
        background-color: #0b59a6;
}
.recruitment .list li:hover > strong{
    transform: rotate(0deg);
}
.recruitment .view{
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    z-index: 20;
    overflow: auto;
}
.recruitment .view::-webkit-scrollbar{
    width: 2px;
    background-color: #e5e5e5;
}
.recruitment .view::-webkit-scrollbar-thumb{
    background-color: #0fa8e7;
}
.recruitment .view h3{
    font-size: 20px ;
    text-align: center;
    margin-bottom: 20px;
}
.recruitment .view > div{
    top: 50%;
    left: 50%;
    width: 1470px;
    max-width: 95%;
    margin-top: 5px;
    padding: 100px 0 45px;
    background-color: white;
    box-shadow: 0px -2px 20px -5px;
    transform: translate(-50%,-50%);
    box-sizing: border-box;
}
.recruitment .view > div > .close{
    top: 35px;
    right: 35px;
    width: 38px;
    height: 38px;
    background: url("../../assets/Talents/develop/close.png") no-repeat center;
    cursor: pointer;
}
.recruitment .view > div > div{
    width: 777px;
    margin: auto;
}

.recruitment .view > div .top{
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #f4f4f4;
    font-size: 0;
    text-align: center;
}
.recruitment .view > div .top div{
    color: #939393;
    font-size: 16px;
}
.recruitment .view > div .top div + div{
    margin-left: 50px;
}
.recruitment .view > div .top div i{
    width: 14px;
    height: 14px;
    margin-right: 5px;
    background: url("../../assets/Talents/develop/biaoqian.png") no-repeat center;
}
.recruitment .view > div .top div i,
.recruitment .view > div .top div span{
    vertical-align: middle;
}
.recruitment .view > div .content{
    width: 777px;
    margin: auto;
    margin-bottom: 60px;
    color: #686868;
    font-size: 14px;
    line-height: 25px;
}
.recruitment .view > div .content .s{
    margin-top: 25px;
}
.recruitment .view > div .href{
    text-align: center;
}
.recruitment .view > div .href a{
    padding: 12px 45px;
    background-color: #0b59a6;
    color: white;
    font-size: 21px;
}
.recruitment .view > div em{
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #0b59a6;
}

.view-form::-webkit-scrollbar{
    width: 2px;
    background-color: #e5e5e5;
}
.view-form::-webkit-scrollbar-thumb{
    background-color: #0fa8e7;
}
.view-form{
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.1);
    z-index: 21;
    overflow: auto;
}
.view-form > div{
    top: 50%;
    left: 50%;
    padding: 50px 30px;
    width: 500px;
    max-width: 100%;
    background-color: white;
    box-shadow: 0px 0px 50px -20px;
    transform: translate(-50%,-50%);
}
.view-form > div h4{
    margin-bottom: 50px;
    font-size: 21px;
    text-align: center;
}
.view-form > div div{
    margin-bottom: 10px;
    color: #4c4c4c;
}
.view-form > div div span{
    display: inline-block;
    width: 17%;
    text-align: right;
}
.view-form > div div input,
.view-form > div div textarea{
    width: 82%;
    border: 1px solid #bfbfbf;
    text-indent: 1em;
    box-sizing: border-box;
}
.view-form > div div input{
    height: 38px;
}
.view-form > div div textarea{
    height: 123px;
    padding: 10px 0;
    vertical-align: top;
    resize: none;
}
.view-form > div div label{
    display: inline-block;
    margin-left: 18%;
    padding: 10px 40px;
    background-color: #f0f0f0;
}
.view-form > div div input[type=file]{
    display: none;
}
.view-form button{
    display: block;
    width: 181px;
    height: 47px;
    margin: 60px auto 0;
    background-color: #0b59a6;
    color: white;
    border: none;
    cursor: pointer;
}
.view-form .close{
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background: url("../../assets/Talents/develop/close.png") no-repeat center/100%;
    cursor: pointer;
}
.content .label{
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}
.content .icon{
    width: 14px;
    height: 14px;
    margin-right: 5px;
    background: url(../../assets/Talents/develop/biaoqian.png) no-repeat center;
    display: inline-block;
}
.content .fl{
    color: #939393;
    font-size: 16px;
}
.content .clearfix{
    display:flex;
    justify-content: space-around;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 20px;
    width:70%;
    margin: auto;
}
.content .p{
    padding-top: 40px;
    width:70%;
    margin: auto;
}
.el-button >>> {
    padding: 7px;
}
.click-form{
    padding: 12px 45px;
    background-color: #0b59a6;
    color: white;
    font-size: 21px;
}
.href{
    text-align:center;
    padding-bottom: 20px;
    padding-top: 20px;
}
.el-form-item >>>.el-form-item__content{
    width:300px;
}
.el-form-item >>>{
    padding-bottom: 20px;
}
.upload{
    transition:all .3s;
    margin-left:120px;
}
.uploadBtn{
    width:100px;
    line-height: 26px;
    background: #409EFF;
    color:#fff;
}
.uploadBtn:active{
    background: #0b59a6;
}
.clearfix{
    display: flex;
    flex-flow: wrap;
    justify-content:center;
}
.icon-box{
    justify-content: space-around;
}
.el-dialog__wrapper >>>.formStyle{
    max-width: 529px;
    transition:all .3s;
}
.el-dialog__wrapper >>> .dialogStyle{
    max-width: 986px;
    min-width: 310px;
    width: 80%;
}
@media screen and (max-width: 1140px){
    .recruitment .list li {
        padding: 30px 35px 15px;
    }
}
@media screen and (max-width: 1000px){
    .el-dialog__wrapper >>>.formStyle{
        width:495px!important;
    }
}
@media screen and (max-width: 768px){
    .recruitment .list li {
        width: 47%;
        margin-left: 2%;
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 555px){
    .recruitment .list li {
        width: 80%;
        margin-left: 2%;
        margin-bottom: 30px;
    }
    .el-dialog__wrapper >>>.formStyle{
        width:300px!important;
    }
    .el-dialog__wrapper >>>.formStyle .el-form-item__label{
        width:68px!important;
    }
    .el-dialog__wrapper >>>.formStyle .el-form-item__content{
        margin-left: 70px!important;
        width:180px!important;
    }
    .el-dialog__wrapper >>>.formStyle .el-input{
        width:180px!important;
    }
    .upload{
        margin-left:20px;
    }
}

</style>